// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails, { $ } from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "stylesheets/application"
import TomSelect from "tom-select"


document.addEventListener("turbolinks:load", () => {
  if (document.querySelector('#grid-gender')) {
    var config = {
      create: false,
      searchField: false,
      sortField: {
        field: "text",
        direction: "asc"
      }
    };
    new TomSelect('#grid-gender',config);
  }
  if (document.querySelector('#grid-ethnicity')) {
    var config = {
      create: false,
      searchField: false,
      sortField: {
        field: "text",
        direction: "asc"
      }
    };
    new TomSelect('#grid-ethnicity',config);
  }
  if (document.querySelector('#grid-type')) {
    var config = {
      create: false,
      searchField: false,
      sortField: {
        field: "text",
        direction: "asc"
      }
    };
    new TomSelect('#grid-type',config);
  }
  if (document.querySelector('#grid-venue')) {
    var config2 = {
      create: false,
      sortField: {
        field: "text",
        direction: "asc"
      }
    };
    new TomSelect('#grid-venue',config2);
  }

  if (document.querySelector('#grid-dob-day')) {
    var config2 = {
      create: false,
      maxOptions: 200
    };
    new TomSelect('#grid-dob-day',config2);
    new TomSelect('#grid-dob-month',config2);
    new TomSelect('#grid-dob-year',config2);
  }

  function refreshCalendar(period) {
    let elements = document.querySelectorAll("[data-period]");

    elements.forEach((element) => {
      element.style.display = "none";
    });

    let elements2 = document.querySelectorAll("[data-period='"+period+"']");

    elements2.forEach((element) => {
      element.style.display = "block";
    });
  }

  if (document.querySelector('#grid-months')) {
    var config = {
      create: false,
      searchField: false,
      sortField: {
        field: "text",
        direction: "asc"
      }
    };
    var grid_month = new TomSelect('#grid-months',config);

    if (document.querySelector("a[data-period]")) {
      let earliest_available_period = document.querySelector("a[data-period]").dataset.period;
      document.querySelector('#grid-months').value = earliest_available_period;
      grid_month.setValue(earliest_available_period);
      refreshCalendar(earliest_available_period);
    }
    else {
      refreshCalendar(document.querySelector('#grid-months').value);
    }


    document.querySelector('#grid-months').addEventListener("change", (event) => {
      refreshCalendar(document.querySelector('#grid-months').value);
    });
  }

  const links = document.querySelectorAll(
    "input[data-checkmark-radio]"
  );
  links.forEach((element) => {
    element.addEventListener("change", (event) => {
      document.querySelector('#booking-button').disabled = false
      document.querySelector('#booking-button2').disabled = false
    });
  });

  function checkTypeField() {
    if (document.querySelector('#grid-type')) {
      if (document.querySelector('#grid-type').value == "covid") {
        document.querySelector('#grid-subtype').disabled = false
        document.querySelector('#appointment-conditional').style.display = "inline"
      } else {
        document.querySelector('#grid-subtype').disabled = true
        document.querySelector('#grid-subtype').value = ""
        document.querySelector('#appointment-conditional').style.display = "none"
      }
    }
  }
  
  checkTypeField();
  const types = document.querySelectorAll(
    "#grid-type"
  );
  types.forEach((element) => {
    element.addEventListener("change", (event, n) => {
      checkTypeField();
    });
  });

  
  document.addEventListener('submit', function (event) {
    if (document.querySelector('#booking-button')) {
      document.querySelector('#booking-button').disabled = true
      document.querySelector('#booking-button').textContent = "Submitting..."
      document.querySelector('#booking-button2').disabled = true
      document.querySelector('#booking-button2').textContent = "Submitting..."
    }
  });
});